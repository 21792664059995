<template>
    <ion-page data-pageid="home">
        <ion-content :scroll-events="false">
            <ChinhSuaThongTinCaNhanVue
                TieuDe="Ngày sinh"
                :model="moment(model.NgaySinh).format('DD/MM/yyyy')"
                keyChinhSua="ngaySinh"
            >
                <template #content>
                    <DxValidationGroup ref="formValidation">
                        <span class="font-12">Ngày sinh (*)</span>
                        <DxDateBox
                            v-model="model.NgaySinh"
                            v-model:value="model.NgaySinh"
                            displayFormat="dd/MM/yyyy"
                            :openOnFieldClick="true"
                            ref="refTab1"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            pickerType="rollers"
                            type="date"
                            ><DxValidator>
                                <DxRequiredRule
                                    message="Ngày sinh không được bỏ trống!"
                            /></DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                    </DxValidationGroup>
                </template>
            </ChinhSuaThongTinCaNhanVue>
        </ion-content>
    </ion-page>
</template>
<script>
import ChinhSuaThongTinCaNhanVue from "../../../src/components/_Common/ChinhSuaThongTinCaNhan";
import moment from "moment";
import { DxRequiredRule } from "devextreme-vue/data-grid";
import {
    DxDateBox,
    DxValidationGroup,
    DxValidator,
    DxButton,
} from "devextreme-vue";
import { IonContent, IonPage } from "@ionic/vue";

export default {
    layout: "application",
    components: {
        ChinhSuaThongTinCaNhanVue,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxButton,
        IonContent,
        IonPage,
    },
    props: {},
    data() {
        return {
            model: { NgaySinh: new Date() },
        };
    },
    methods: {},
    created() {},
    mounted() {
        let refSdt = this.$refs.refTab1.instance;
        if (refSdt) {
            refSdt.focus();
        }
        this.model.NgaySinh = this.$route.query.data ? moment(this.$route.query.data).format() : new Date();
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>